import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_gori/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_gori/assets/ts/_utils";
import { WithChildren } from "../../_gori/helpers";

const PrivateRoutes = () => {
  const CustomerManagementPage = lazy(
    () => import("../modules/customer-management/CustomerManagementPage")
  );
  const AccountManagementPage = lazy(
    () => import("../modules/account-management/AccountManagementPage")
  );
  const SalesToolPage = lazy(
    () => import("../modules/sales-tool/SalesToolPage")
  );
  const MidPage = lazy(() => import("../modules/mid/MidPage"));
  const RatePage = lazy(() => import("../modules/rate/RatePage"));
  const ToolsPage = lazy(() => import("../modules/tools/ToolsPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="customer-management/*"
          element={
            <SuspenseView>
              <CustomerManagementPage />
            </SuspenseView>
          }
        />
        <Route
          path="sales-tool/*"
          element={
            <SuspenseView>
              <SalesToolPage />
            </SuspenseView>
          }
        />
        <Route
          path="mid/*"
          element={
            <SuspenseView>
              <MidPage />
            </SuspenseView>
          }
        />
        <Route
          path="rate/*"
          element={
            <SuspenseView>
              <RatePage />
            </SuspenseView>
          }
        />
        <Route
          path="account-management/*"
          element={
            <SuspenseView>
              <AccountManagementPage />
            </SuspenseView>
          }
        />
        <Route
          path="tools/*"
          element={
            <SuspenseView>
              <ToolsPage />
            </SuspenseView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspenseView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
