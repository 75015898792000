import { useIntl } from "react-intl";
import { PageTitle } from "../../../_gori/layout/core";
const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
    </>
  );
};

export { DashboardWrapper };
