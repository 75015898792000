/* eslint-disable react/jsx-no-target-blank */
// import { useIntl } from "react-intl";
// import { KTSVG } from "../../../helpers";
// import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/general/gen014.svg"
        title="Dashboard"
      />
      {/*<AsideMenuItemWithSub*/}
      {/*  to="/customer-management"*/}
      {/*  icon="/media/icons/duotune/communication/com005.svg"*/}
      {/*  title="Customer Management"*/}
      {/*>*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/customer-management/company"*/}
      {/*    hasBullet={true}*/}
      {/*    title="Companies"*/}
      {/*  />*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/customer-management/meter"*/}
      {/*    hasBullet={true}*/}
      {/*    title="Meters"*/}
      {/*  />*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/customer-management/user"*/}
      {/*    hasBullet={true}*/}
      {/*    title="Users"*/}
      {/*  />*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/customer-management/ach"*/}
      {/*    hasBullet={true}*/}
      {/*    title="ACH Accounts"*/}
      {/*  />*/}
      {/*</AsideMenuItemWithSub>*/}
      {/*<AsideMenuItem*/}
      {/*  to="/mid"*/}
      {/*  icon="/media/icons/duotune/general/gen063.svg"*/}
      {/*  title="USPS MIDs"*/}
      {/*/>*/}
      {/*<AsideMenuItem*/}
      {/*  to="/tools"*/}
      {/*  icon="/media/icons/duotune/general/gen064.svg"*/}
      {/*  title="Internal Tools"*/}
      {/*/>*/}
      <AsideMenuItem
        to="/sales-tool"
        icon="/media/icons/duotune/general/gen065.svg"
        title="Sales Tool"
      />
      {/*<AsideMenuItem*/}
      {/*  to="/rate"*/}
      {/*  icon="/media/icons/duotune/general/gen066.svg"*/}
      {/*  title="Rate Management"*/}
      {/*/>*/}

      {/*<AsideMenuItemWithSub*/}
      {/*  to="/account-management"*/}
      {/*  icon="/media/icons/duotune/general/gen067.svg"*/}
      {/*  title="Account Management"*/}
      {/*>*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/account-management/user"*/}
      {/*    hasBullet={true}*/}
      {/*    title="Users"*/}
      {/*  />*/}
      {/*  <AsideMenuItem*/}
      {/*    to="/account-management/role"*/}
      {/*    hasBullet={true}*/}
      {/*    title="Roles"*/}
      {/*  />*/}
      {/*</AsideMenuItemWithSub>*/}
    </>
  );
}
